<template>
  <component
    :is="input.componentName"
    :input="input"
    :disabled="disabled"
    :multiple="multiple"
    :error-messages="errorMessages"
    @update:value="updateValue"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"

export default {
  name: "InputHandler",

  components: {
    TextField: () => import(/* webpackChunkName: 'TextField' */ "./TextField"),
    TextArea: () => import(/* webpackChunkName: 'TextArea' */ "./TextArea"),
    DropDown: () => import(/* webpackChunkName: 'Dropdown' */ "./DropDown"),
    CheckBox: () => import(/* webpackChunkName: 'CheckBox' */ "./CheckBox"),
    RadioButton: () =>
      import(/* webpackChunkName: 'RadioButton' */ "./RadioButton"),
  },

  props: {
    path: String,
    disabled: Boolean,
    multiple: Boolean,
    setValue: String,
    options: Array,
    addRules: Array,
    errorMessages: String,
  },

  watch: {
    setValue(newVal) {
      this.updateValue(newVal)
    },
  },

  data: () => ({}),

  computed: {
    ...mapState({}),

    ...mapGetters({
      generatePathToField: "data/generatePathToField",
    }),

    input() {
      const quote = this.generatePathToField(this.path)
      const value =
        this.setValue === undefined || this.setValue === ""
          ? quote.value
          : this.setValue
      const options = this.options === undefined ? quote.options : this.options
      const rules =
        !!this.addRules && this.addRules.length > 0 ? this.addRules : []
      const specialCaseRules = [
        ...rules,
        (v) =>
          /(^(0{0,1}|([0-9]?[0-9]?[0-9]?[0-9]?))(\.[0-9]{1,4})?$)$/.test(v) ||
          "Values must be in XXXX.XXXX format.",
      ]
      const lengthRules = [
        (v) =>
          /(^(0{0,1}|([0-9]?[0-9]?[0-9]?))(\.[0-9]{1,4})?$)$/.test(v) ||
          "Values must be in XXX.XXXX format.",
        ...rules,
      ]
      const floatRules = [
        (v) =>
          /(^(0{0,1}|([0-9]?[0-9]?))(\.[0-9]{1,4})?$)$/.test(v) ||
          "Values must be in XX.XXXX format.",
        ...rules,
      ]
      const negativeFloatRules = [
        (v) =>
          /(^(0{0,1}|(-?[0-9]?[0-9]?))(\.[0-9]{1,4})?$)$/.test(v) ||
          "Values must be in XX.XXXX format.",
        ...rules,
      ]
      const standardDropdownRules = [...rules]

      const standardText = {
        ...quote,
        value,
        componentName: "text-field",
        rules,
      }
      const areaText = {
        ...quote,
        componentName: "text-area",
      }
      const float = {
        ...quote,
        componentName: "text-field",
        rules: floatRules,
      }
      const negativeFloat = {
        ...quote,
        componentName: "text-field",
        rules: negativeFloatRules,
      }
      const specialCase = {
        ...quote,
        componentName: "text-field",
        value,
        rules: specialCaseRules,
      }
      const radioButton = {
        ...quote,
        componentName: "radio-button",
        options,
      }
      const standardDropdown = {
        ...quote,
        componentName: "drop-down",
        options,
        value,
        rules: standardDropdownRules,
      }
      const statesDropdown = {
        ...quote,
        componentName: "drop-down",
        options: [
          {
            value: "AL",
            display: "AL - Alabama",
          },
          {
            value: "AK",
            display: "AK - Alaska",
          },
          {
            value: "AZ",
            display: "AZ - Arizona",
          },
          {
            value: "AR",
            display: "AR - Arkansas",
          },
          {
            value: "CA",
            display: "CA - California",
          },
          {
            value: "CO",
            display: "CO - Colorado",
          },
          {
            value: "CT",
            display: "CT - Connecticut",
          },
          {
            value: "DE",
            display: "DE - Delaware",
          },
          {
            value: "DC",
            display: "DC - District of Columbia",
          },
          {
            value: "FL",
            display: "FL - Florida",
          },
          {
            value: "GA",
            display: "GA - Georgia",
          },
          {
            value: "HI",
            display: "HI - Hawaii",
          },
          {
            value: "ID",
            display: "ID - Idaho",
          },
          {
            value: "IL",
            display: "IL - Illinois",
          },
          {
            value: "IN",
            display: "IN - Indiana",
          },
          {
            value: "IA",
            display: "IA - Iowa",
          },
          {
            value: "KS",
            display: "KS - Kansas",
          },
          {
            value: "KY",
            display: "KY - Kentucky",
          },
          {
            value: "LA",
            display: "LA - Louisiana",
          },
          {
            value: "ME",
            display: "ME- Maine",
          },
          {
            value: "MD",
            display: "MD - Maryland",
          },
          {
            value: "MA",
            display: "MA - Massachusetts",
          },
          {
            value: "MI",
            display: "MI - Michigan",
          },
          {
            value: "MN",
            display: "MN - Minnesota",
          },
          {
            value: "MS",
            display: "MS - Mississippi",
          },
          {
            value: "MO",
            display: "MO - Missouri",
          },
          {
            value: "MT",
            display: "MT - Montana",
          },
          {
            value: "NE",
            display: "NE - Nebraska",
          },
          {
            value: "NV",
            display: "NV - Nevada",
          },
          {
            value: "NH",
            display: "NH - New Hampshire",
          },
          {
            value: "NJ",
            display: "NJ - New Jersey",
          },
          {
            value: "NM",
            display: "NM - New Mexico",
          },
          {
            value: "NY",
            display: "NY - New York",
          },
          {
            value: "NC",
            display: "NC - North Carolina",
          },
          {
            value: "ND",
            display: "ND - North Dakota",
          },
          {
            value: "OH",
            display: "OH - Ohio",
          },
          {
            value: "OK",
            display: "OK - Oklahoma",
          },
          {
            value: "OR",
            display: "OR - Oregon",
          },
          {
            value: "PA",
            display: "PA - Pennsylvania",
          },
          {
            value: "RI",
            display: "RI - Rhode Island",
          },
          {
            value: "SC",
            display: "SC - South Carolina",
          },
          {
            value: "SD",
            display: "SD - South Dakota",
          },
          {
            value: "TN",
            display: "TN - Tennessee",
          },
          {
            value: "TX",
            display: "TX - Texas",
          },
          {
            value: "UT",
            display: "UT - Utah",
          },
          {
            value: "VT",
            display: "VT - Vermont",
          },
          {
            value: "VA",
            display: "VA - Virginia",
          },
          {
            value: "WA",
            display: "WA - Washington",
          },
          {
            value: "WV",
            display: "WV - West Virginia",
          },
          {
            value: "WI",
            display: "WI - Wisconsin",
          },
          {
            value: "WY",
            display: "WY - Wyoming",
          },
        ],
      }
      const length = {
        ...quote,
        componentName: "text-field",
        value,
        rules: lengthRules,
      }
      const name = {
        componentName: "text-field",
        label: "Name",
        required: true,
      }
      const email = {
        componentName: "text-field",
        label: "Email",
        required: true,
      }
      const checkBox = {
        ...quote,
        componentName: "check-box",
        value,
      }

      switch (quote.type) {
        case "FLOAT":
          return float
        case "ELEVATION":
          return negativeFloat
        case "BOOLEAN":
          return checkBox
        case "STANDARD_DROPDOWN":
          return standardDropdown
        case "SPECIAL_CASE":
          return specialCase
        case "RADIO_BUTTON":
          return radioButton
        case "STATES_DROPDOWN":
          return statesDropdown
        case "LENGTH":
          return length
        case "NAME":
          return name
        case "EMAIL":
          return email
        case "AREA_TEXT":
          return areaText
        default:
          return standardText
      }
    },
  },

  methods: {
    ...mapActions({
      updateStateValue: "data/updateStateValue",
    }),

    updateValue(newValue) {
      return this.updateStateValue({
        path: this.path,
        value: newValue,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: -30px;
}
</style>
